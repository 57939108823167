import { Config } from './../../../app-config';
import { ExperienceService } from './../../shared/service/experience/experience.service';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-experience',
  templateUrl: './experience.component.html',
  styleUrls: ['./experience.component.scss'],
})
export class ExperienceComponent implements OnInit {
  private themeConfig;
  public expData;

  constructor(protected expService: ExperienceService) {}

  ngOnInit(): void {
    this.themeConfig = Config.config;
    this.expService
      .getExperienceByUser(this.themeConfig.requestId)
      .subscribe((expResult) => {
        this.expData = expResult.datas;
        this.expData.sort((val1, val2) => {
          return (
            new Date(val2.beginDate).getTime() -
            new Date(val1.beginDate).getTime()
          );
        });
      });
  }

  public round(number: number): number {
    return Math.ceil(number);
  }
}
