import { OtherService } from './../../shared/service/other/other.service';
import { IUser } from './../../shared/service/user/user';
import { Config } from './../../../app-config';
import { Component, OnInit, Input, AfterContentInit } from '@angular/core';
import { trigger, transition, useAnimation } from '@angular/animations';
import { bounceIn } from 'ng-animate';
import { UserService } from '../../shared/service/user/user.service';

declare let require: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    trigger('bounce', [
      transition(
        '* => *',
        useAnimation(bounceIn, {
          // Set the duration to 5seconds and delay to 2seconds
          params: {
            timing: 3,
            delay: 0,
            a: '3000px',
            b: '-25px',
            c: '10px',
            d: '-5px',
          },
        })
      ),
    ]),
  ],
})
export class HomeComponent implements OnInit, AfterContentInit {
  @Input() themeType: string;
  public usr: IUser;
  public request: any;
  public aboutData;
  public bounce: number = 1;
  private themeConfig: any;

  constructor(
    protected usrService: UserService,
    protected otherService: OtherService
  ) {
    setInterval(() => {
      this.bounce = this.bounce < 3 ? this.bounce + 1 : 1;
    }, 3000);
  }

  ngOnInit(): void {
    this.themeConfig = Config.config;
    this.usrService
      .getOneRequest(this.themeConfig.requestId)
      .subscribe((res) => {
        this.request = res.datas;
        this.usrService
          .getCurrentUser(res.datas.userId)
          .subscribe((usrResult) => {
            this.usr = usrResult.datas;
          });
      });
    this.otherService
      .getOtherByUser(this.themeConfig.requestId)
      .subscribe((otherResult) => {
        this.aboutData = otherResult.datas;
      });
  }

  ngAfterContentInit() {
    setTimeout(() => {
      const Parallax = require('parallax-js');
      const scene = document.getElementById('scene');
      const parallaxInstance1 = new Parallax(scene, {
        relativeInput: false,
      });
    }, 2000);
  }

  scrollTo(section) {
    const sectionHtml = document.querySelector('#' + section);
    if (sectionHtml !== null) {
      sectionHtml.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
  }
}
