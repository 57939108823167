<div class="container" *ngIf="scoolData && scoolData.length > 0">

  <!-- section title -->
  <h2 class="section-title wow fadeInUp">Parcours Scolaire</h2>

  <div class="spacer-60"></div>

  <div class="row">

    <div class="col-md-4" *ngFor="let school of scoolData">
      <div class="service-box rounded data-background padding-30 m-tb-15 text-center text-light shadow-blue bg-primary">
        <img src="assets/images/service-1.svg" alt="{{ school.title }}" />
        <h3 class="mb-3 mt-0">{{ school.school }}</h3>
        <p class="mb-0" [innerHTML]="school.degree"></p>
        <strong>{{ school.beginDate | date:"yyyy" }} - {{ school.endDate | date:"yyyy" }}</strong>
      </div>
      <div class="spacer-30 d-md-none d-lg-none"></div>
    </div>
  </div>

  <div class="mt-5 text-center">
    <p class="mb-0">Intéressé par mon parcours? <a href="javascript:" (click)="scrollTo('contact')">Cliquez ici</a> pour me contacter! 👋</p>
  </div>

</div>
