import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from './../../../../app-config';
import { Response } from '../response';

@Injectable({
  providedIn: 'root',
})
export class OtherService {
  constructor(protected http: HttpClient) {}

  getOneOther(id: any) {
    return this.http.get<Response>(`${Config.config.apiUrl}/v1/other/${id}`);
  }

  getOtherByUser(id: any) {
    return this.http.get<Response>(
      `${Config.config.apiUrl}/v1/other/request/${id}`
    );
  }
}
