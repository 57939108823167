import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ScrollSpyDirective } from '../scroll-spy/scroll-spy.directive';
import { SectionsModule } from '../sections/sections.module';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [ScrollSpyDirective],
  imports: [CommonModule, SectionsModule, HttpClientModule],
  exports: [ScrollSpyDirective, HttpClientModule, SectionsModule],
  providers: [ScrollSpyDirective],
})
export class SharedModule {}
