export class Config {
  public static config = {
    theme: 'collapsed', // vertical, collapsed, horizontal
    themeType: 'light', // default, dark, light
    apiUrl: 'https://webcv-api.2tcorp.org',
    adminUrl: 'https://webcv.2tcorp.org',
    requestId: '14', // User's owner of the CV
    secret: '6Ld6QKUeAAAAAMDjRjGA826RN8eGnSK-sXjL8lJd',
  };
}
