<div class="container">

  <!-- section title -->
  <h2 class="section-title wow fadeInUp">Expériences Professionnelles</h2>

  <div class="spacer-60"></div>

  <div class="row">

    <div *ngIf="expData" [ngClass]="{'col-md-12': expData.length <= 3, 'col-md-6': expData.length > 3}">

      <!-- timeline wrapper -->
      <div class="timeline edu rounded bg-white shadow-dark padding-30 overflow-hidden">

        <!-- timeline item -->
        <div *ngFor="let exp of expData; let i=index" >
          <div class="timeline-container wow fadeInUp" *ngIf="(expData.length <= 3 && i <= 3 ) || (expData.length > 3 && i < round(expData.length / 2))">
            <div class="content">
              <span class="time">{{ exp.beginDate | date:"MMM yyyy" }} - {{ (exp.isActual) ? "Actuellement" : exp.endDate | date:"MMM yyyy" }}</span>
              <h3 class="title"><span [innerHTML]="exp.title"></span> - <em>{{ exp.society }}</em></h3>
              <p [innerHTML]="exp.tasks" ></p>
            </div>
          </div>
          <!-- main line -->
          <span class="line"></span>
        </div>
      </div>

    </div>

    <div class="col-md-6" *ngIf="expData && expData.length > 3">

      <!-- responsive spacer -->
      <div class="spacer-30 d-md-none d-lg-none"></div>

      <!-- timeline wrapper -->
      <div class="timeline exp bg-white shadow-dark rounded padding-30 overflow-hidden">

        <!-- timeline item -->
        <div *ngFor="let exp of expData; let i=index" >
          <div class="timeline-container wow fadeInUp" *ngIf="i >= round(expData.length / 2)" data-wow-delay="0.8s">
            <div class="content">
              <span class="time">{{ exp.beginDate | date:"MMM yyyy" }} - {{ (exp.isActual) ? "Actuellement" : exp.endDate | date:"MMM yyyy" }}</span>
              <h3 class="title"> <span [innerHTML]="exp.title"></span> - <em >{{ exp.society }}</em></h3>
              <p [innerHTML]="exp.tasks" ></p>
            </div>
          </div>
          <!-- main line -->
          <span class="line"></span>
        </div>

      </div>

    </div>

  </div>

</div>
