<div class="container">

  <!-- section title -->
  <h2 class="section-title wow fadeInUp">A propos de moi</h2>

  <div class="spacer-60"></div>

  <div class="row">

    <div class="col-md-3">
      <div class="text-center">
        <!-- avatar image -->
        <img *ngIf="!usr || !usr.picture" src="https://www.charlesguene.fr/wp-content/plugins/all-in-one-seo-pack/images/default-user-image.png" width="100" alt="" class="mb-4 img-round" />
        <img *ngIf="usr && usr.picture" src="{{usr.picture}}" width="100" alt="{{ usr.surname  }} {{  usr.firstname }}" class="mb-4 img-round" />
      </div>
      <div class="text-center mt-4" style="position: relative; left: 35%;">
        <div class="float-left" style="margin: 5px;" *ngFor="let lang of langData">
          <img width="25" src="{{ themeConfig.adminUrl }}{{ lang.Language.flag }}" alt="{{ lang.Language.value }}" title="{{ lang.Language.value }}" />
        </div>
      </div>
      <!-- <div class="text-center mt-4">
        <video width="100%" height="200" controls>
          <source src="https://videos.2tcorp.online/videos/piscine-C-J11-0.mp4">
          Votre navigateur ne supporte pas cette video de présentation
        </video>
      </div> -->
      <div class="spacer-30 d-md-none d-lg-none">
      </div>
    </div>

    <div class="col-md-9 triangle-left-md triangle-top-sm">
      <div class="rounded bg-white shadow-dark padding-30">
        <div class="row">
          <div class="col-md-6">
            <!-- about text -->
            <p *ngIf="aboutData" [innerHTML]="aboutData.presentationMess"></p>
            <div class="mt-3">
              <a href="javascript:" (click)="download()" class="btn btn-default">Download CV</a>
            </div>
            <div class="spacer-30 d-md-none d-lg-none"></div>
          </div>
          <div class="col-md-6">
            <!-- skill item -->
            <div class="skill-item" *ngFor="let skill of skillData">
              <div class="skill-info clearfix">
                <h4 class="float-left mb-3 mt-0">{{ skill.mainSkill }}</h4>
                <span class="float-right">{{ skill.Level.title }}</span>
              </div>
              <ngb-progressbar type="danger" [value]="30" *ngIf="skill.Level.label == 'BEGINNER'"></ngb-progressbar>
              <ngb-progressbar type="warning" [value]="55" *ngIf="skill.Level.label == 'INTERMEDIATE'"></ngb-progressbar>
              <ngb-progressbar type="primary" [value]="90" *ngIf="skill.Level.label == 'EXPERT'"></ngb-progressbar>
              <div class="spacer-20"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <!-- row end -->

  <div class="spacer-70"></div>

  <div class="row">
    <div class="col-md-3 col-sm-6" *ngFor="let hobby of hobbyData">
      <!-- fact item -->
      <div class="fact-item">
        <span class="icon icon-fire {{ hobby.icon }}"></span>
        <div class="details">
          <p class="mb-0">{{ hobby.label }}</p>
        </div>
      </div>
      <div class="spacer-30 d-md-none d-lg-none"></div>
    </div>
  </div>

</div>
