import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from './../../../../app-config';
import { Response } from '../response';

@Injectable({
  providedIn: 'root',
})
export class ExperienceService {
  constructor(protected http: HttpClient) {}

  getOneExperience(id: any) {
    return this.http.get<Response>(
      `${Config.config.apiUrl}/v1/experience/${id}`
    );
  }

  getExperienceByUser(userId: any) {
    return this.http.get<Response>(
      `${Config.config.apiUrl}/v1/experience/request/${userId}`
    );
  }
}
