import { CompanyService } from './../../shared/service/company/company.service';
import { Config } from './../../../app-config';
import { UserService } from './../../shared/service/user/user.service';
import { Component, OnInit } from '@angular/core';
import { IUser } from '../../shared/service/user/user';
import { LocalStorage } from '../../shared/service/local-storage/local-storage.service';
import { toast } from 'toast-notification-alert';
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
  public themeConfig: any;
  public usr: IUser;
  private isCaptchaOk = false;
  public lang = 'fr';
  public aCaptcha = {
    a: 0,
    b: 0,
    result: 0,
    response: '',
  };
  public oContact = {
    name: '',
    subject: '',
    email: '',
    message: '',
    captcha: null,
  };

  constructor(
    protected usrService: UserService,
    protected company: CompanyService
  ) {}

  ngOnInit(): void {
    this.themeConfig = Config.config;
    this.usrService
      .getOneRequest(this.themeConfig.requestId)
      .subscribe((res) => {
        this.usrService
          .getCurrentUser(res.datas.userId)
          .subscribe((usrResult) => {
            this.usr = usrResult.datas;
          });
      });
    this.generateCaptcha();
  }

  /**
   * onSubmit     Function to send email from form
   *
   * @returns void
   */
  onSubmit(): void {
    // On vérifie la réponse de l'utilisateur pour s'assurer que c'est bien un humain
    if (this.aCaptcha.result !== Number(this.aCaptcha.response)) {
      toast.show({
        title: '',
        message:
          'La vérification du captcha de calcul a échoué. Merci de réessayer',
        newestOnTop: false,
        type: 'error',
      });
      this.generateCaptcha(); // Regenerate operation if it was false

      return;
    }

    if (this.oContact.name.trim() === '') {
      // Name cannot be empty
      toast.show({
        title: '',
        message: 'Merci de renseigner votre nom',
        newestOnTop: false,
        type: 'error',
      });
    }
    if (this.oContact.email.trim() === '') {
      // Name cannot be empty
      toast.show({
        title: '',
        message: 'Merci de renseigner votre email',
        newestOnTop: false,
        type: 'error',
      });
    }
    if (this.oContact.message.trim() === '') {
      // Name cannot be empty
      toast.show({
        title: '',
        message: 'Le message est obligatoire',
        newestOnTop: false,
        type: 'error',
      });
    }

    if (
      this.oContact.name.trim() === '' ||
      this.oContact.email.trim() === '' ||
      this.oContact.message.trim() === ''
    ) {
      return;
    }

    const contact: any = {
      name: this.oContact.name,
      to: this.usr.email,
      from: this.oContact.email,
      purpose: 'Message provenant du formulaire de mon CV' + new Date(),
      message: `
      <i>Ceci est envoyé en tant que demande d'information depuis le formulaire de contact du site https://2tcorp.org.</i> <br/>
      <b>Nom: </b>${this.oContact.name} <br />
      <b>Email: </b>${this.oContact.email}<br />
      <b>Titre: </b>${this.oContact.subject}<br />
      <b>Message:</b> <br /> ${this.oContact.message} <br><br>
      <b>~Fin du Message.~</b> `,
    };

    this.company.sendMail(contact).subscribe((res) => {
      if (res.code === 200) {
        toast.show({
          title: '',
          message:
            'Votre message a bien été envoyé. Nous reviendrons vers vous si nécessaire',
          newestOnTop: false,
          type: 'success',
        });
      } else {
        toast.show({
          title: '',
          message: 'Une erreur est survenu. Merci de rééssayer',
          newestOnTop: false,
          type: 'error',
        });
      }
      this.generateCaptcha(); // Regenerate captcha if mail sent correctly.
    });
  }

  public handleSuccess(event) {
    console.log(event);
  }

  /**
   * Only allow number values on input
   *
   * @param event User's input event
   * @returns
   */
  public numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  /**
   * Function to generate the captcha operation verification.
   *
   * Will be used to identify if the sender is human or not
   */
  private generateCaptcha() {
    this.aCaptcha.a = this.randomInteger(2, 20);
    this.aCaptcha.b = this.randomInteger(2, 20);
    while (this.aCaptcha.b == this.aCaptcha.a) {
      // Second value and first value should never be the same
      this.aCaptcha.b = this.randomInteger(2, 20 + this.aCaptcha.b);
    }
    this.aCaptcha.result = this.aCaptcha.a + this.aCaptcha.b; // Sum of first and second values
    this.aCaptcha.response = '';
  }

  private randomInteger(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
}
