import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Response } from '../response';
import { Config } from './../../../../app-config';

@Injectable({
  providedIn: 'root',
})
export class SpokenLanguageService {
  constructor(protected http: HttpClient) {}

  getOneSpokenLanguage(id: any) {
    return this.http.get<Response>(`${Config.config.apiUrl}/v1/spoken/${id}`);
  }

  getSpokenLanguageOfUser(userId: any) {
    return this.http.get<Response>(
      `${Config.config.apiUrl}/v1/spoken/request/${userId}`
    );
  }
}
