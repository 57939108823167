import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Response } from '../response';
import { Config } from './../../../../app-config';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(protected http: HttpClient) {}

  getCurrentUser(id) {
    return this.http.get<Response>(`${Config.config.apiUrl}/v1/user/${id}`);
  }
  getOneRequest(id) {
    return this.http.get<Response>(`${Config.config.apiUrl}/v1/request/${id}`);
  }
}
