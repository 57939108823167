import { Config } from './../../../app-config';
import { ScolarityService } from './../../shared/service/school/school.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-scolarity',
  templateUrl: './scolarity.component.html',
  styleUrls: ['./scolarity.component.scss'],
})
export class ScolarityComponent implements OnInit {
  private themeConfig;
  public scoolData;

  constructor(protected schoolService: ScolarityService) {}

  ngOnInit(): void {
    this.themeConfig = Config.config;
    this.schoolService
      .getScolarityByUser(this.themeConfig.requestId)
      .subscribe((scoolResult) => {
        this.scoolData = scoolResult.datas;
      });
  }

  scrollTo(section) {
    const sectionHtml = document.querySelector('#' + section);
    if (sectionHtml !== null) {
      sectionHtml.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
  }
}
