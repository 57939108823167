import { SkillService } from './../../shared/service/skill/skill.service';
import { OtherService } from './../../shared/service/other/other.service';
import { UserService } from './../../shared/service/user/user.service';
import { Component, OnInit } from '@angular/core';
import { Config } from './../../../app-config';
import { IUser } from '../../shared/service/user/user';
import { SpokenLanguageService } from '../../shared/service/spokenLanguage/spokenLanguage.service';
import { HobbyService } from '../../shared/service/hobby/hobby.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
})
export class AboutComponent implements OnInit {
  public themeConfig: any;
  public usr: IUser;
  public aboutData;
  public skillData;
  public hobbyData;
  public langData;
  private requestData;

  constructor(
    protected usrService: UserService,
    protected skillService: SkillService,
    protected hobbyService: HobbyService,
    protected spokenLangService: SpokenLanguageService,
    protected otherService: OtherService
  ) {}

  ngOnInit(): void {
    this.themeConfig = Config.config;
    this.usrService
      .getOneRequest(this.themeConfig.requestId)
      .subscribe((res) => {
        this.requestData = res.datas;
        this.usrService
          .getCurrentUser(res.datas.userId)
          .subscribe((usrResult) => {
            this.usr = usrResult.datas;
          });
      });

    this.otherService
      .getOtherByUser(this.themeConfig.requestId)
      .subscribe((otherResult) => {
        this.aboutData = otherResult.datas;
      });
    this.skillService
      .getSkillOfUser(this.themeConfig.requestId)
      .subscribe((skillResult) => {
        this.skillData = skillResult.datas;
      });
    this.hobbyService
      .getHobbyByUser(this.themeConfig.requestId)
      .subscribe((hobbyResult) => {
        this.hobbyData = hobbyResult.datas;
      });
    this.spokenLangService
      .getSpokenLanguageOfUser(this.themeConfig.requestId)
      .subscribe((langResult) => {
        this.langData = langResult.datas;
      });
  }

  download() {
    let linkSource = this.requestData.doc;
    const downloadLink = document.createElement('a');
    linkSource = linkSource.replace(/&#x2F;/g, '/');
    const filename = `cv_${this.requestData.id}_${this.usr.firstname}.pdf`;
    downloadLink.href = linkSource;
    downloadLink.download = filename;
    downloadLink.click();
  }
}
