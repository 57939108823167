import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from './../../../../app-config';
import { Response } from '../response';

@Injectable({
  providedIn: 'root',
})
export class ScolarityService {
  constructor(protected http: HttpClient) {}

  getOneScolarity(id: any) {
    return this.http.get<Response>(
      `${Config.config.apiUrl}/v1/scolarity/${id}`
    );
  }

  getScolarityByUser(userId: any) {
    return this.http.get<Response>(
      `${Config.config.apiUrl}/v1/scolarity/request/${userId}`
    );
  }
}
