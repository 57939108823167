<div class="container">

  <!-- section title -->
  <h2 class="section-title wow fadeInUp">Restez en contact</h2>

  <div class="spacer-60"></div>

  <div class="row">

    <div class="col-md-4">
      <!-- contact info -->
      <div class="contact-info">
        <h3 class="wow fadeInUp">Echangeons ensemble!</h3>
        <p class="wow fadeInUp" *ngIf="usr && usr.email">Merci de m'écrire si mon profil vous intéresse. 👋</p>
      </div>
    </div>

    <div class="col-md-8">
      <!-- Contact Form -->
      <form id="contact-form" class="contact-form mt-6" method="post" (submit)="onSubmit()">
        <div class="messages"></div>
        <div class="row">
          <div class="column col-md-6">
            <!-- Name input -->
            <div class="form-group">
              <input *ngIf="oContact" type="text" class="form-control" [(ngModel)]="oContact.name" name="InputName" id="InputName" placeholder="Votre nom" required="required" data-error="Votre nom est nécessaire.">
              <div class="help-block with-errors"></div>
            </div>
          </div>

          <div class="column col-md-6">
            <!-- Email input -->
            <div class="form-group">
              <input *ngIf="oContact" type="email" class="form-control" id="InputEmail" [(ngModel)]="oContact.email" name="InputEmail" placeholder="Email" required="required" data-error="L'email est obligatoire.">
              <div class="help-block with-errors"></div>
            </div>
          </div>

          <div class="column col-md-12">
            <!-- Email input -->
            <div class="form-group">
              <input *ngIf="oContact" type="text" class="form-control" id="InputSubject" name="InputSubject" [(ngModel)]="oContact.subject" placeholder="Objet" required="required" data-error="L'objet est obligatoire.">
              <div class="help-block with-errors"></div>
            </div>
          </div>

          <div class="column col-md-12">
            <!-- Message textarea -->
            <div class="form-group">
              <textarea  *ngIf="oContact" name="InputMessage" id="InputMessage" class="form-control" rows="5" [(ngModel)]="oContact.message" placeholder="Message" required="required" data-error="Le Message est important."></textarea>
              <div class="help-block with-errors"></div>
            </div>
          </div>
          <div class="col-md-12 pb-2">
            <div class="rounded bg-white shadow-dark padding-30">
              <div class="row pb-4">
                <div class="col-md-12">
                  <span class="jasmine-description">Cette question permet de vérifier que vous n'êtes pas un robot.</span>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <strong> Question Mathématique &nbsp;&nbsp;</strong><span class="text-danger">*</span>
                </div>
                <div class="col-2 float-right">
                  <label>{{ aCaptcha.a }} + {{ aCaptcha.b }} =</label>
                </div>
                <div class="col-md-3">
                  <input *ngIf="aCaptcha" type="text" class="w-100" (keypress)="numberOnly($event)" [(ngModel)]="aCaptcha.response" name="InputCaptchaResponse" required="required">
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <em class="caption">Trouvez la solution de ce problème Mathématique simple et saisissez le résultat. Par example pour 1 + 3, saisissez 4</em>
                </div>
              </div>
            </div>
          </div>

        </div>

        <button type="submit" name="submit" id="submit" value="Submit" class="btn btn-default">Envoyer</button><!-- Send Button -->

      </form>
      <!-- Contact Form end -->
    </div>

  </div>

</div>
