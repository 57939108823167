import { Config } from './../../../../app-config';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Response } from '../response';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  constructor(protected http: HttpClient) {}

  sendMail(data) {
    return this.http.post<Response>(
      `${Config.config.apiUrl}/v1/contact/send-mail`,
      data
    );
  }
}
